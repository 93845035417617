<template>
  <path
    d="M10.0003 14.5H10.8003C11.9204 14.5 12.4805 14.5 12.9083 14.282C13.2846 14.0903 13.5906 13.7843 13.7823 13.408C14.0003 12.9802 14.0003 12.4201 14.0003 11.3V5.7C14.0003 4.5799 14.0003 4.01984 13.7823 3.59202C13.5906 3.21569 13.2846 2.90973 12.9083 2.71799C12.4805 2.5 11.9204 2.5 10.8003 2.5H5.20031C4.08021 2.5 3.52016 2.5 3.09233 2.71799C2.71601 2.90973 2.41005 3.21569 2.2183 3.59202C2.00031 4.01984 2.00031 4.5799 2.00031 5.7V6.5M3.60032 9.16667H5.73388C6.29396 9.16667 6.574 9.16667 6.78792 9.27567C6.97609 9.37155 7.12907 9.52454 7.22494 9.71272C7.33393 9.92664 7.33391 10.2067 7.33388 10.7668L7.33375 12.9001C7.33371 13.4601 7.33369 13.7401 7.2247 13.954C7.12882 14.1422 6.97584 14.2951 6.78768 14.391C6.57378 14.5 6.29377 14.5 5.73375 14.5H3.60074C3.04079 14.5 2.76081 14.5 2.54692 14.391C2.35878 14.2952 2.2058 14.1422 2.10992 13.9541C2.00091 13.7402 2.00085 13.4603 2.00074 12.9003L2.00032 10.767C2.00021 10.2068 2.00015 9.92676 2.10913 9.7128C2.20499 9.5246 2.35797 9.37158 2.54616 9.27569C2.76009 9.16667 3.04016 9.16667 3.60032 9.16667Z"
    stroke="white"
    stroke-width="1.3"
    stroke-linecap="square"
    stroke-linejoin="round"
  />
  <path
    d="M11.333 5.16732L8.50033 8M8.50033 8H11.0755M8.50033 8V5.42483"
    stroke="white"
    stroke-width="1.2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>
